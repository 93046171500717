import {useContext, useState} from 'react';
import {StoreContext} from "../../../stores/StoreLoader";
import {observer} from "mobx-react";
import styles from "./PersonModal.module.scss";
import {useMachine} from "@xstate/react/lib";
import {FetchMachine} from "../../machines/FetchMachine";
import FontAwesome from "../../utilities/FontAwesome";
import {buildCssUrlString, capitalize, hasHTML} from "../../../utils/StringUtilities";
import {htmlToMarkdown} from "../../markdown/conversions";
import InlineTextEditor from "../../utilities/InlineTextEditor";
import Markdown from "../../utilities/Markdown";
import NotificationManager from "../../notifications/NotificationManager";
import {getAvatarUrl} from "../../blocks/SchoolBlocks/blockUtils";
import ClickableLink from "../../utilities/ClickableLink";
import WatsonApi from "../../../backends/WatsonApi";
import {compressImage} from "../../../utils/DataUtilities";
import dynamic from "next/dynamic";

const PhoneNumber = dynamic(() => import("../../utilities/PhoneNumber"));

export function formatPhoneNumber(value: number): string {
    const phoneNumber = String(value).replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) {
        return phoneNumber;
    } else if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    } else if (phoneNumberLength === 11) {
        return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}${phoneNumber.length > 11 ? ` x${phoneNumber.slice(11)}` : ""}`;
    } else {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}${phoneNumber.length > 10 ? ` x${phoneNumber.slice(10)}` : ""}`;
    }
}

function deobfuscateEmailAddress(obfuscatedEmail) {
    let letters = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz';

    if (obfuscatedEmail) {
        // If a non-logged-in user 1) opens a staff details model for StaffMemberA, 2) clicks
        // 'Click to View Email', 3) closes the modal, 4) re-opens the modal for StaffMemberA
        // a second time without refreshing the page, and 5) re-clicks the 'Click to View Email'
        // button a second time, it's possible the email will already be decoded, and that state
        // will not yet be updated. In this case, the email WILL contain the character '@', which
        // will never be the case with an obfuscated email. In this case, we should NOT attempt
        // to deobfuscate again, as it will RE-obfuscate the email, preventing the user from
        // being able to read it. In this case, return the `obfuscatedEmail` arg as the email.
        if (obfuscatedEmail.includes("@")) {
            return obfuscatedEmail;
        }
        let n = obfuscatedEmail.length % 26;

        let rep = letters.substring(n * 2) + letters.substring(0, n * 2);
        letters += '@"';
        rep += "|*";
        const email = obfuscatedEmail.replace(/[a-z|*]/gi, function (s) {
            let index = rep.indexOf(s);
            let letter = s;
            if (index !== -1) {
                return letters.charAt(index);
            }
            return letter;
        });

        if (email) {
            return email
        }
    }
    return false;
}

function ObfuscatedEmailField(props: {
    obfuscatedEmail: string
    canEditContact: boolean
    blockObj: IPersonBlock
}) {
    const {userStore} = useContext(StoreContext);

    // email is only obfuscated if user is guest
    const [obfuscated, setObfuscated] = useState(!userStore.id);

    function handleClick() {
        props.blockObj.setAttributes({
            blockModel: {
                ...props.blockObj.blockModel,
                email: deobfuscateEmailAddress(props.obfuscatedEmail),
            },
        });
        setObfuscated(false);
    }

    if (obfuscated) {
        return <button onClick={handleClick}>[Click to view email]</button>
    } else {
        const email = props.blockObj.blockModel.email;
        let emailFieldContent;
        if (props.canEditContact && email) {
            // Can edit and there's an email, OR can't edit, there's an email, but it's not an actual address ("Please login to view" message)
            emailFieldContent = <span>{email}</span>;
        } else if (props.canEditContact) {
            // Can edit, but there's not an email
            emailFieldContent = <span>{"Enter email..."}</span>;
        } else if (email) {
            // Can't edit, there is a valid email
            emailFieldContent = <ClickableLink href={`mailto:${email}`}>{email}</ClickableLink>;
        }

        return <InlineTextEditor
            key="email"
            text={email}
            wrapperClassName={styles.editable}
            handleTextChange={(value) => props.blockObj.setAttributes({
                blockModel: {
                    ...props.blockObj.blockModel,
                    email: value,
                },
            })}
            placeholder="Enter email..."
            canEdit={props.canEditContact}
            isSimple={true}
            showHoverUI={true}
        >
            {emailFieldContent}
        </InlineTextEditor>
    }
}

export const TeacherBio = observer((props: {
    blockObj: IPersonBlock,
    canEdit: boolean,
    canEditContact: boolean,
    isPublicInfo: boolean,
}) => {
    const {organizationStore, interfaceStore} = useContext(StoreContext);
    const showStaffDirectoryEmailAddresses = organizationStore.organization.json_data.settings.appearance.showStaffDirectoryEmailAddresses;
    const personModel = props.blockObj.blockModel

    const [currentAvatarUpload, sendAvatarUpload] = useMachine(FetchMachine);

    async function handleSubmit() {
        let result = {};
        const client = await WatsonApi();
        try {
            result = await client.apis.organizations.organizations_users_partial_update({
                organization_pk: organizationStore.currentOrganization.id,
                id: personModel.id,
                data: {
                    "id": personModel.id,
                    "fname": personModel.fname,
                    "lname": personModel.lname,
                    "title": personModel.title,
                    "phone": personModel.phone,
                    "mobile_phone": personModel.mobile_phone,
                    "email": personModel.email,
                    "bio": personModel.bio,
                },
            });
            if (result["status"] === 200) NotificationManager.success("Staff member updated successfully.");
        } catch (e) {
            console.error(`ERROR: ${e.toString()}`);
            if (result["status"] === 403) NotificationManager.error("You do not have permission to edit this staff member.");
            else NotificationManager.error("Error updating staff member.");
        }
    }

    async function handleOutsideClick(event) {
        const saveButtonFocused = event.target === document.getElementById("saveBio");
        if (saveButtonFocused) {
            await handleSubmit();
        }
    }

    async function handleAvatarChange(event) {
        const files = event.target.files,
            userId = personModel.id;

        if (!files.length) {
            return;
        }
        let file = files[0]
        if (file['name'].indexOf("'") !== -1) {
            var blob = file.slice(0, file.size, file.type);
            file = new File([blob], file['name'].replace(/'/g, "%27"), {
                type: file.type,
                lastModified: file.lastModified
            })
        }
        sendAvatarUpload("FETCH");
        // Try compressing file if the size is over 1MB
        if (file.size > 1000000) {
            file = await compressImage(file,1)
        }
        try {
            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_users_update_picture({
                organization_pk: organizationStore.organization.id,
                id: userId,
                file,
            });
            const result = JSON.parse(response.data);
            if (result.avatar) {
                props.blockObj.setAttributes({
                    blockModel: {
                        ...props.blockObj.blockModel,
                        avatarUrl: result.avatar,
                    },
                })
                sendAvatarUpload("FULFILL");

                NotificationManager.success('Profile picture has been updated.');
            } else {
                sendAvatarUpload("REJECT");
                NotificationManager.error(result.error);
            }
        } catch (e) {
            sendAvatarUpload("REJECT");
            NotificationManager.error(e.response?.body?.message, 'Image Upload Error', 4000);
        }

    }

    let bio = personModel.bio || "";
    bio = hasHTML(bio) ? htmlToMarkdown(bio, {currentFullUrl: interfaceStore.currentFullUrl}) : bio;

    const provider = organizationStore.organization.json_data.settings?.identity?.provider;
    const titleSyncingEnabled = organizationStore.organization.json_data.settings?.identity[provider]?.userFieldsToOverrideAndSync?.title;
    const canEditTitle = titleSyncingEnabled ? props.canEditContact : props.canEdit;
    const displayTitleTooltip = props.canEdit && titleSyncingEnabled;

    return <>
        <div className="sb-teacher-modal-top row">
            <div className="col-md-4">
                <label htmlFor="input-teacher-avatar" id="teacher-img-placeholder"
                       className="sb-teacher-img-placeholder"
                       title="Click to upload photo">
                    <div id="teacher-avatar-picture" className="sb-circular-profile-image sb-teacher-img-size-big"
                         style={{backgroundImage: buildCssUrlString(getAvatarUrl(personModel))}}
                         title={`${personModel.fname} ${personModel.lname}`}>
                        {props.canEdit && <>
                            {currentAvatarUpload.value === "PENDING" ?
                                <div className={styles.uploading}>
                                    <div>
                                        <FontAwesome prefix={'fas'} name={'fa-spinner'} size={"3x"} spin/>
                                    </div>
                                </div> :
                                <div className="sb-teacher-select-img-container">
                                    <FontAwesome prefix={'fas'} name={'fa-camera'}/>
                                </div>}
                        </>}
                    </div>
                </label>
                {props.canEdit && <input
                    type="file"
                    className="hidden"
                    name="avatar"
                    id="input-teacher-avatar"
                    onChange={handleAvatarChange}
                    accept={"image/*"}
                />}
            </div>
            <div className="col-md-8 sb-teacher-modal-info notranslate">
                <h2>
                    {(personModel.fname || props.canEditContact) && <InlineTextEditor
                        key="fname"
                        wrapperClassName={styles.editable}
                        placeholder={"First name..."}
                        canEdit={props.canEditContact}
                        text={personModel.fname}
                        handleTextChange={(value) => props.blockObj.setAttributes({
                            blockModel: {
                                ...props.blockObj.blockModel,
                                fname: value,
                            },
                        })}
                        isSimple={true}
                        showHoverUI={true}
                    ><span>{personModel.fname || "First name..."}</span></InlineTextEditor>} {(personModel.lname || props.canEditContact) &&
                    <InlineTextEditor
                        key="lname"
                        placeholder={"Last name..."}
                        wrapperClassName={styles.editable}
                        canEdit={props.canEditContact}
                        text={personModel.lname}
                        handleTextChange={(value) => props.blockObj.setAttributes({
                            blockModel: {
                                ...props.blockObj.blockModel,
                                lname: value,
                            },
                        })}
                        isSimple={true}
                        showHoverUI={true}
                    ><span>{personModel.lname || "Last name..."}</span></InlineTextEditor>}
                </h2>
                {(personModel.title || canEditTitle) && <h4 key={1}>
                    <InlineTextEditor
                        key="title"
                        placeholder={"Title..."}
                        wrapperClassName={styles.editable}
                        canEdit={canEditTitle}
                        text={personModel.title || ""}
                        handleTextChange={(value) => props.blockObj.setAttributes({
                            blockModel: {
                                ...props.blockObj.blockModel,
                                title: value,
                            },
                        })}
                        isSimple={true}
                        showHoverUI={true}
                    >
                        <span
                            className={displayTitleTooltip ? styles.tooltip : ""}
                            data-content={displayTitleTooltip ? `Title must be edited in ${capitalize(provider)} Directory` : ""}>
                        {personModel.title || "Title..."}
                    </span>
                    </InlineTextEditor>
                </h4>}
                {(personModel.phone || props.canEditContact) && <div className="sb-teacher-phone">
                    <InlineTextEditor
                        key="phone"
                        placeholder={"Enter phone..."}
                        wrapperClassName={styles.editable}
                        canEdit={props.canEditContact}
                        text={personModel.phone ? formatPhoneNumber(personModel.phone) : ""}
                        handleTextChange={(value) => {
                            const phoneNumber = Number(value?.replace(/[^\d]/g, ""));
                            props.blockObj.setAttributes({
                                blockModel: {
                                    ...props.blockObj.blockModel,
                                    phone: phoneNumber,
                                },
                            })
                        }}
                        isSimple={true}
                        showHoverUI={true}
                    >
                        {personModel.phone ?
                            <PhoneNumber number={formatPhoneNumber(personModel.phone)}
                                         isLinked={!props.canEditContact}/> :
                            <span>Enter phone...</span>}
                    </InlineTextEditor> {props.isPublicInfo && personModel.phone ? "Phone" : ""}
                </div>}
                {showStaffDirectoryEmailAddresses && (personModel.email || props.canEdit) &&
                    <div className="sb-teacher-email">
                        <ObfuscatedEmailField
                            obfuscatedEmail={personModel.email}
                            canEditContact={props.canEdit}
                            blockObj={props.blockObj}
                        />
                    </div>}
            </div>
        </div>
        {(personModel.bio || props.canEdit) && <InlineTextEditor
            key="bio"
            canEdit={props.canEdit}
            placeholder={"Enter biography..."}
            text={bio}
            handleTextChange={(value) => props.blockObj.setAttributes({
                blockModel: {
                    ...props.blockObj.blockModel,
                    bio: value,
                }
            })}
            handleSave={handleOutsideClick}
            wrapperClassName="sb-teacher-description"
            isSimple={false}
            showHoverUI={true}
        >
            <Markdown source={bio || "Enter biography..."} withStyles/>
        </InlineTextEditor>}
        {props.canEdit && <div className="row" style={{textAlign: "right"}}>
            <button id={'saveBio'} onClick={handleSubmit}
                    className="btn sb-organization-color-element-bg">Save Bio
            </button>
        </div>}
    </>
});
